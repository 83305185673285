import React, { FC, useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { LocationModel } from '@nimles/models';
import { useStaticQuery, graphql } from 'gatsby';
import { SiteMetadata } from '../../types';

declare global {
  interface Window {
    google: any;
  }
}

const containerStyle = {
  width: '100%',
  flex: 1,
  minHeight: '300px',
  borderRadius: '4px',
};

interface Props {
  center?: LocationModel;
  zoom?: number;
  locations?: LocationModel[];
  mapStyle?: any;
  fitLocations?: boolean;
  disableDefaultUI?: boolean;
  onChange?: (bounds: {
    northEast: LocationModel;
    southWest: LocationModel;
  }) => void;
}

const MyComponent: FC<Props> = ({
  children,
  center,
  zoom,
  locations,
  mapStyle,
  fitLocations,
  disableDefaultUI,
  onChange,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          googleApiKey
        }
      }
    }
  `);

  const siteMetadata: SiteMetadata = data.site.siteMetadata;

  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: siteMetadata.googleApiKey,
  });

  useEffect(() => {
    if (map && locations) {
      if (fitLocations || !center) {
        const bounds = new google.maps.LatLngBounds();
        locations.forEach((location) => bounds.extend(toLatLng(location)));
        if (center) {
          bounds.extend(toLatLng(center));
        }
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
        console.log('zoom', map.getZoom());
      }
    }
  }, [map, locations]);

  const onLoad = (map) => {
    setMap(map);
  };

  const onUnmount = () => {
    setMap(null);
  };

  const onIdle = () => {
    if (onChange) {
      const bounds = map?.getBounds();
      const northEast = bounds?.getNorthEast();
      const southWest = bounds?.getSouthWest();
      if (northEast && southWest) {
        onChange({
          northEast: {
            latitude: northEast.lat(),
            longitude: northEast.lng(),
          },
          southWest: {
            latitude: southWest.lat(),
            longitude: southWest.lng(),
          },
        });
      }
    }
  };

  console.log('Map');

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyle || containerStyle}
      center={toLatLng(center)}
      zoom={zoom || 10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onIdle={onIdle}
      options={{ disableDefaultUI }}
    >
      {children}
    </GoogleMap>
  ) : null;
};

export const Map: FC<Props> = React.memo(MyComponent);

export const toLatLng = (location: LocationModel): google.maps.LatLngLiteral =>
  location
    ? {
        lat: location.latitude,
        lng: location.longitude,
      }
    : null;

export const toLocation = (
  location: google.maps.LatLngLiteral
): LocationModel =>
  location
    ? {
        latitude: location.lat,
        longitude: location.lng,
      }
    : null;

export const distance = (
  location1: LocationModel,
  location2: LocationModel,
  unit?: string
) => {
  const { latitude: lat1, longitude: lng1 } = location1;
  const { latitude: lat2, longitude: lon2 } = location2;

  if (lat1 == lat2 && lng1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lng1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};
